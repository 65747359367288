import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/Update';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import * as moment from 'moment';
import 'moment/locale/es';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import app from '../Firebase';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-force-tabpanel-${index}`}
			aria-labelledby={`scrollable-force-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `scrollable-force-tab-${index}`,
		'aria-controls': `scrollable-force-tabpanel-${index}`
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper
	},
	appBar: {
		position: 'relative',
		background: '#BECD00'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	list: {
		textAlign: 'center'
	},
	listItemTextPrimary: {
		fontSize: '1em',
		color: '#7d7d7d'
	},
	listItemTextSecondary: {
		fontSize: '1.5em',
		color: '#0d0d0c'
	},
	dividerInfo: {
		margin: theme.spacing(0, 110),
		[theme.breakpoints.down('lg')]: {
			margin: theme.spacing(0, 70)
		},
		[theme.breakpoints.down('md')]: {
			margin: theme.spacing(0, 20)
		},
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0, 10)
		}
	},
	form: {
		margin: 'auto',
		maxWidth: 500
	},
	inputFormUpdate: {
		textAlign: 'center',
		width: '100%',
		margin: 'auto',
		justifyContent: 'center',
		paddingTop: theme.spacing(1)
	}
}));

export default function ScrollableTabsButtonForce(props) {
	const classes = useStyles();
	moment.locale('es');
	const [ value, setValue ] = React.useState(0);
	const [ adress, setAdress ] = React.useState(props.event.adress);
	const [ category, setCategory ] = React.useState('time');
	const [ dateEmailFirst, setDateEmailFirst ] = React.useState(
		moment(props.event.dateEmailFirst).format('YYYY-MM-DDThh:mm')
	);
	const [ dateEmailSecond, setDateEmailSecond ] = React.useState(
		moment(props.event.dateEmailSecond).format('YYYY-MM-DDThh:mm')
	);
	const [ diffusion, setDiffusion ] = React.useState(props.event.diffusion);
	const [ district, setDistrict ] = React.useState(props.event.district);
	const [ emailOfContact, setEmailOfContact ] = React.useState(props.event.emailOfContact);
	const [ entity, setEntity ] = React.useState(props.event.entity);
	const [ featuresGroup, setFeaturesGroup ] = React.useState(props.event.featuresGroup);
	const [ inscriptions, setInscriptions ] = React.useState(props.event.inscriptions);
	const [ kindOfGroup, setKindOfGroup ] = React.useState(props.event.kindOfGroup);
	const [ level, setLevel ] = React.useState(props.event.level);
	const [ nameOfContact, setNameOfContact ] = React.useState(props.event.nameOfContact);
	const [ participants, setParticipants ] = React.useState(props.event.participants);
	const [ phoneOfContact, setPhoneOfContact ] = React.useState(props.event.phoneOfContact);
	const [ remark, setRemark ] = React.useState(props.event.remark);
	const [ title, setTitle ] = React.useState(props.event.title);
	const [ startt, setStartt ] = React.useState(moment(props.event.start).format('YYYY-MM-DDTHH:mm'));
	const start = moment(props.event.start).format('DD/MM/YYYY');
	const timeStart = moment(props.event.start).format('HH:mm');
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const onUpdate = () => {
		if (startt !== '' && dateEmailFirst !== '' && dateEmailSecond !== '') {
			const db = app.firestore();
			db
				.collection('calendars')
				.doc(props.event.calendarId)
				.collection('schedule')
				.doc(props.event.id)
				.set({
					adress: adress,
					category: category,
					dateEmailFirst: new Date(dateEmailFirst),
					dateEmailSecond: new Date(dateEmailSecond),
					diffusion: diffusion,
					district: district,
					emailOfContact: emailOfContact,
					end: new Date(startt),
					entity: entity,
					featuresGroup: featuresGroup,
					inscriptions: inscriptions,
					kindOfGroup: kindOfGroup,
					level: level,
					nameOfContact: nameOfContact,
					participants: participants,
					phoneOfContact: phoneOfContact,
					remark: remark,
					title: title,
					start: new Date(startt)
				})
				.then(function() {
					props.handleSetTextAlert('El calendario se ha actualizado correctamente!');
					props.handleOpenSuccessAlert(true);
					props.hanleCloseUpdateCalendar();
					window.location.reload();
				})
				.catch(function(error) {
					props.handleSetTextAlert('No se ha podido actualizar el calendario.');
					props.handleOpenErrorAlert(true);
				});
		} else {
			props.handleSetTextAlert('Faltan campos por rellenar.');
			props.handleOpenErrorAlert(true);
		}
	};

	const onDelete = () => {
		const db = app.firestore();
		db
			.collection('calendars')
			.doc(props.event.calendarId)
			.collection('schedule')
			.doc(props.event.id)
			.delete()
			.then(function() {
				props.handleSetTextAlert('El calendario se ha eliminado correctamente!');
				props.handleOpenSuccessAlert(true);
				props.hanleCloseUpdateCalendar();
				window.location.reload();
			})
			.catch(function(error) {
				props.handleSetTextAlert('No se ha podido eliminado el calendario.');
				props.handleOpenErrorAlert(true);
			});
	};

	return (
		<div className={classes.root}>
			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleChange}
					variant="fullWidth"
					indicatorColor="primary"
					textColor="primary"
					aria-label="scrollable force tabs example"
					centered
				>
					<Tab label="Visualizar Evento" icon={<AssignmentIcon />} {...a11yProps(0)} />
					<Tab label="Actualizar Evento" icon={<UpdateIcon />} {...a11yProps(1)} />
					<Tab label="Eliminar Evento" icon={<DeleteIcon />} {...a11yProps(1)} />
				</Tabs>
			</AppBar>
			<TabPanel value={value} index={0}>
				<List>
					<ListItem>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							className={classes.list}
							primary="Nombre del taller"
							secondary={props.event.title}
						/>
					</ListItem>
					<Divider className={classes.dividerInfo} />
					<ListItem>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							className={classes.list}
							primary="Dia del taller"
							secondary={start}
						/>
					</ListItem>
					<Divider className={classes.dividerInfo} />
					<ListItem className={classes.list}>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							primary="Hora de inicio"
							secondary={timeStart}
						/>
					</ListItem>
					<Divider className={classes.dividerInfo} />
					<ListItem className={classes.list}>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							primary="Numero de perticipantes previstos"
							secondary={props.event.participants}
						/>
					</ListItem>
					<Divider className={classes.dividerInfo} />
					<ListItem className={classes.list}>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							primary="¿Inscripciones previas?"
							secondary={props.event.inscriptions === true ? 'Si' : 'No'}
						/>
					</ListItem>
					<Divider className={classes.dividerInfo} />
					<ListItem className={classes.list}>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							primary="¿Como piensan hacer la difusión?"
							secondary={props.event.diffusion}
						/>
					</ListItem>
					<Divider className={classes.dividerInfo} />
					<ListItem>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							className={classes.list}
							primary="Nombre del grupo, entidad o equipamineto"
							secondary={props.event.entity}
						/>
					</ListItem>
					<Divider className={classes.dividerInfo} />
					<ListItem>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							className={classes.list}
							primary="Distrito"
							secondary={props.event.district}
						/>
					</ListItem>
					<Divider className={classes.dividerInfo} />
					<ListItem className={classes.list}>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							primary="Tipo de grupo"
							secondary={props.event.kindOfGroup}
						/>
					</ListItem>
					<Divider className={classes.dividerInfo} />
					<ListItem className={classes.list}>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							primary="Caracteristicas del grupo"
							secondary={props.event.featuresGroup}
						/>
					</ListItem>
					<Divider className={classes.dividerInfo} />
					<ListItem className={classes.list}>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							primary="Nivel del taller"
							secondary={props.event.level}
						/>
					</ListItem>
					<Divider className={classes.dividerInfo} />
					<ListItem className={classes.list}>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							primary="Nombre i apellidos de la persona de contacto"
							secondary={props.event.nameOfContact}
						/>
					</ListItem>
					<Divider className={classes.dividerInfo} />
					<ListItem className={classes.list}>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							primary="Telèfono de la persona de contacto"
							secondary={props.event.phoneOfContact}
						/>
					</ListItem>
					<Divider className={classes.dividerInfo} />
					<ListItem className={classes.list}>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							primary="Correo electrónico de la persona de contacto"
							secondary={props.event.emailOfContact}
						/>
					</ListItem>
					<Divider className={classes.dividerInfo} />
					<ListItem className={classes.list}>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							primary="Lugar y dirección de realización del taller"
							secondary={props.event.adress}
						/>
					</ListItem>
					<Divider className={classes.dividerInfo} />
					<ListItem className={classes.list}>
						<ListItemText
							classes={{
								primary: classes.listItemTextPrimary,
								secondary: classes.listItemTextSecondary
							}}
							primary="Observaciones"
							secondary={props.event.remark}
						/>
					</ListItem>
				</List>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<form className={classes.form} noValidate>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						Nombre del evento
					</Typography>
					<TextField
						variant="outlined"
						margin="normal"
						fullWidth
						id="title"
						label="Nombre"
						type="text"
						name="title"
						autoFocus
						value={title}
						onChange={(e) => {
							setTitle(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						Fecha y Hora del evento
					</Typography>
					<TextField
						variant="outlined"
						id="startt"
						margin="normal"
						fullWidth
						label="Fecha y Hora"
						type="datetime-local"
						InputLabelProps={{
							shrink: true
						}}
						value={startt}
						onChange={(e) => {
							setStartt(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						Participantes del evento
					</Typography>
					<TextField
						variant="outlined"
						id="participants"
						margin="normal"
						fullWidth
						label="Participantes"
						type="number"
						value={participants}
						onChange={(e) => {
							setParticipants(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						¿Permiten inscriptiones?
					</Typography>
					<FormControlLabel
						margin="normal"
						id="incripciones"
						className={classes.inputFormUpdate}
						control={
							<Switch
								checked={inscriptions}
								onChange={(e) => {
									setInscriptions(!inscriptions);
								}}
								value={true}
							/>
						}
						label="Incripciones"
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						¿Como piensan hacer la difusión?
					</Typography>
					<TextField
						variant="outlined"
						id="diffusion"
						margin="normal"
						fullWidth
						label="Difusión"
						type="text"
						value={diffusion}
						onChange={(e) => {
							setDiffusion(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						Nombre del grupo, entidad o equipamineto
					</Typography>
					<TextField
						variant="outlined"
						id="entity"
						margin="normal"
						fullWidth
						label="Entidad"
						type="text"
						value={entity}
						onChange={(e) => {
							setEntity(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						Distrito
					</Typography>
					<TextField
						variant="outlined"
						id="district"
						margin="normal"
						fullWidth
						label="Distrito"
						type="text"
						value={district}
						onChange={(e) => {
							setDistrict(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						Tipo de grupo
					</Typography>
					<TextField
						variant="outlined"
						id="kindOfGroup"
						margin="normal"
						fullWidth
						label="Tipo de grupo"
						type="text"
						value={kindOfGroup}
						onChange={(e) => {
							setKindOfGroup(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						Caracteristicas del grupo
					</Typography>
					<TextField
						variant="outlined"
						id="featuresGroup"
						margin="normal"
						fullWidth
						label="Caracteristicas"
						type="text"
						value={featuresGroup}
						onChange={(e) => {
							setFeaturesGroup(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						Nivel del taller
					</Typography>
					<TextField
						variant="outlined"
						id="level"
						margin="normal"
						fullWidth
						label="Nivel"
						type="text"
						value={level}
						onChange={(e) => {
							setLevel(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						Nombre i apellidos de la persona de contacto
					</Typography>
					<TextField
						variant="outlined"
						id="nameOfContact"
						margin="normal"
						fullWidth
						label="Persona de contacto"
						type="text"
						value={nameOfContact}
						onChange={(e) => {
							setNameOfContact(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						Telèfono de la persona de contacto
					</Typography>
					<TextField
						variant="outlined"
						id="phoneOfContact"
						margin="normal"
						fullWidth
						label="Telèfono"
						type="text"
						value={phoneOfContact}
						onChange={(e) => {
							setPhoneOfContact(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						Correo electrónico de la persona de contacto
					</Typography>
					<TextField
						variant="outlined"
						id="emailOfContact"
						margin="normal"
						fullWidth
						label="Telèfono"
						type="text"
						value={emailOfContact}
						onChange={(e) => {
							setEmailOfContact(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						Lugar y dirección de realización del taller
					</Typography>
					<TextField
						variant="outlined"
						id="adress"
						margin="normal"
						fullWidth
						label="Lugar y dirección"
						type="text"
						value={adress}
						onChange={(e) => {
							setAdress(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						Observaciones
					</Typography>
					<TextField
						variant="outlined"
						id="remark"
						margin="normal"
						fullWidth
						label="Observaciones"
						type="text"
						value={remark}
						onChange={(e) => {
							setRemark(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						Fecha primer email recordatorio
					</Typography>
					<TextField
						variant="outlined"
						id="dateEmailFirst"
						margin="normal"
						fullWidth
						label="Fecha y Hora"
						type="datetime-local"
						InputLabelProps={{
							shrink: true
						}}
						value={dateEmailFirst}
						onChange={(e) => {
							setDateEmailFirst(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Typography
						variant="subtitle1"
						style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
					>
						Fecha segundo email recordatorio
					</Typography>
					<TextField
						variant="outlined"
						id="dateEmailSecond"
						margin="normal"
						fullWidth
						label="Fecha y Hora"
						type="datetime-local"
						InputLabelProps={{
							shrink: true
						}}
						value={dateEmailSecond}
						onChange={(e) => {
							setDateEmailSecond(e.target.value);
						}}
						style={{ marginBottom: 30 }}
					/>
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						startIcon={<UpdateIcon />}
						onClick={onUpdate}
						style={{ width: '100%' }}
					>
						Actualizar
					</Button>
				</form>
			</TabPanel>
			<TabPanel value={value} index={2}>
				<Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justify="center"
					style={{ textAlign: 'center', padding: 30 }}
				>
					<Grid item>
						<Typography variant="h4" style={{ textAlign: 'center', marginBottom: 30 }}>
							¿Seguro que quiere eliminar este evento?
						</Typography>
						<Button
							variant="contained"
							color="secondary"
							className={classes.button}
							startIcon={<DeleteIcon />}
							onClick={onDelete}
						>
							Delete
						</Button>
					</Grid>
				</Grid>
			</TabPanel>
		</div>
	);
}
