import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AlertText = (props) => {
	return (
		<div>
			<Snackbar open={props.openSuccess} autoHideDuration={6000} onClose={props.handleCloseSuccessAlert}>
				<Alert onClose={props.handleCloseSuccessAlert} severity="success">
					{props.alertText}
				</Alert>
			</Snackbar>
			<Snackbar open={props.openAlert} autoHideDuration={6000} onClose={props.handleCloseErrorAlert}>
				<Alert onClose={props.handleCloseErrorAlert} severity="error">
					{props.alertText}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default AlertText;
