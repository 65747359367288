import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from '../Router';
import { useAuthState } from 'react-firebase-hooks/auth';
import { userContext } from '../../context/UserContext';
import app from '../Firebase';
import Spinner from '../Spinner';
import './App.css';

const App = () => {
	const [ user, initialising ] = useAuthState(app.auth());

	if (initialising) {
		return (
			<div>
				<Spinner />
			</div>
		);
	}

	return (
		<userContext.Provider
			value={{
				user: user,
				initialising
			}}
		>
			<BrowserRouter>
				<Router />
			</BrowserRouter>
		</userContext.Provider>
	);
};

export default App;
