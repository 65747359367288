import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
		background: '#BECD00'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	list: {
		textAlign: 'center',
		padding: theme.spacing(2, 0)
	},
	listItemTextPrimary: {
		fontSize: '1em',
		color: '#7d7d7d'
	},
	listItemTextSecondary: {
		fontSize: '1.5em',
		color: '#0d0d0c'
	},
	dividerInfo: {
		margin: theme.spacing(0, 110),
		[theme.breakpoints.down('lg')]: {
			margin: theme.spacing(0, 70)
		},
		[theme.breakpoints.down('md')]: {
			margin: theme.spacing(0, 20)
		},
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0, 10)
		}
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogInfo(props) {
	const classes = useStyles();
	moment.locale('es');
	const start = moment(props.event.start).format('DD/MM/YYYY');
	const timeStart = moment(props.event.start).format('HH:mm');
	return (
		<div>
			<Dialog fullScreen open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<Typography variant="subtitle1" className={classes.title}>
							{props.event.title}
						</Typography>
						<IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<Grid container>
					<Grid item xs={12} sm={12}>
						<List>
							<ListItem>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									className={classes.list}
									primary="Nombre del taller"
									secondary={props.event.title}
								/>
							</ListItem>
							<Divider className={classes.dividerInfo} />
							<ListItem>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									className={classes.list}
									primary="Dia del taller"
									secondary={start}
								/>
							</ListItem>
							<Divider className={classes.dividerInfo} />
							<ListItem className={classes.list}>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									primary="Hora de inicio"
									secondary={timeStart}
								/>
							</ListItem>
							<Divider className={classes.dividerInfo} />
							<ListItem className={classes.list}>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									primary="Numero de perticipantes previstos"
									secondary={props.event.participants}
								/>
							</ListItem>
							<Divider className={classes.dividerInfo} />
							<ListItem className={classes.list}>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									primary="¿Inscripciones previas?"
									secondary={props.event.inscriptions === true ? 'Si' : 'No'}
								/>
							</ListItem>
							<Divider className={classes.dividerInfo} />
							<ListItem className={classes.list}>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									primary="¿Como piensan hacer la difusión?"
									secondary={props.event.diffusion}
								/>
							</ListItem>
							<Divider className={classes.dividerInfo} />
							<ListItem>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									className={classes.list}
									primary="Nombre del grupo, entidad o equipamineto"
									secondary={props.event.entity}
								/>
							</ListItem>
							<Divider className={classes.dividerInfo} />
							<ListItem>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									className={classes.list}
									primary="Distrito"
									secondary={props.event.district}
								/>
							</ListItem>
							<Divider className={classes.dividerInfo} />
							<ListItem className={classes.list}>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									primary="Tipo de grupo"
									secondary={props.event.kindOfGroup}
								/>
							</ListItem>
							<Divider className={classes.dividerInfo} />
							<ListItem className={classes.list}>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									primary="Caracteristicas del grupo"
									secondary={props.event.featuresGroup}
								/>
							</ListItem>
							<Divider className={classes.dividerInfo} />
							<ListItem className={classes.list}>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									primary="Nivel del taller"
									secondary={props.event.level}
								/>
							</ListItem>
							<Divider className={classes.dividerInfo} />
							<ListItem className={classes.list}>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									primary="Nombre i apellidos de la persona de contacto"
									secondary={props.event.nameOfContact}
								/>
							</ListItem>
							<Divider className={classes.dividerInfo} />
							<ListItem className={classes.list}>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									primary="Telèfono de la persona de contacto"
									secondary={props.event.phoneOfContact}
								/>
							</ListItem>
							<Divider className={classes.dividerInfo} />
							<ListItem className={classes.list}>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									primary="Correo electrónico de la persona de contacto"
									secondary={props.event.emailOfContact}
								/>
							</ListItem>
							<Divider className={classes.dividerInfo} />
							<ListItem className={classes.list}>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									primary="Lugar y dirección de realización del taller"
									secondary={props.event.adress}
								/>
							</ListItem>
							<Divider className={classes.dividerInfo} />
							<ListItem className={classes.list}>
								<ListItemText
									classes={{
										primary: classes.listItemTextPrimary,
										secondary: classes.listItemTextSecondary
									}}
									primary="Observaciones"
									secondary={props.event.remark}
								/>
							</ListItem>
						</List>
					</Grid>
				</Grid>
			</Dialog>
		</div>
	);
}
