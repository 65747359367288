import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import SignIn from '../SignIn';

import PrivateRoute from './PrivateRoute'; 

import * as ROUTES from '../../constants/Routes';

const Router = () => {
	return (
		<Switch>
			<Route exact path={ROUTES.SIGN_IN} component={SignIn} />
			<PrivateRoute component={Dashboard}  usersAdmin={true}/>
		</Switch>
	);
};

export default Router;
