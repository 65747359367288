import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import axios from 'axios';
import { useSession } from '../Session';
import CircularProgress from '@material-ui/core/CircularProgress'


const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(2)
    },
    spinner: {
        textAlign: 'center',
        marginTop: theme.spacing(6)
    }
}));

export default function ListUsers({aUsers = [], callbackFromParent}) {
    const classes = useStyles();
    const [ users, setUsers ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState('');
    const [ checked, setChecked ] = React.useState([]);
    const user = useSession();
    
    useEffect(() => {
		const fetchData = async () => {
			const idTokenResult = await user.getIdTokenResult().then((tokenResult) => {
				return tokenResult.token;
			});
			axios.defaults.headers.common = { Authorization: `Bearer ${idTokenResult}` };
			setLoading(true);
			const users = await axios
				.get(process.env.REACT_APP_FIREBASE_API + '/users')
				.then(function(response) {
					// handle success
					setLoading(false);
					setUsers(response.data.users);
					const initialChecks = response.data.users.filter((it) => aUsers.includes(it.uid));
					setChecked(initialChecks);
					callbackFromParent(initialChecks);
				})
				.catch(function(error) {
					// handle error
					setLoading(false);
                    setError(error);
                    console.log(error);
				});
		};
		fetchData();
	}, []);
    
    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        callbackFromParent(newChecked);
      };

      if (loading) {
		return (
			<div className={classes.spinner}>
				 <CircularProgress />
			</div>
		);
	}

	return (
		<List className={classes.root}>
			{users.map((value) => {
				const labelId = `checkbox-list-label-${value.uid}`;

				return (
					<ListItem key={value.uid} role={undefined} dense button onClick={handleToggle(value)}>
						<ListItemIcon>
							<Checkbox
								edge="start"
								checked={checked.indexOf(value) !== -1}
								tabIndex={-1}
								disableRipple
								inputProps={{ 'aria-labelledby': labelId }}
							/>
						</ListItemIcon>
						<ListItemText id={labelId} primary={`${value.displayName}`} />
						<ListItemSecondaryAction>
							<IconButton edge="end" aria-label="user">
								<PersonIcon />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				);
			})}
		</List>
	);
}
