import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import EventIcon from '@material-ui/icons/Event';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PeopleIcon from '@material-ui/icons/People';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { Link } from 'react-router-dom';
import './index.css';

export const userListItems = (
	<div>
		<ListItem button key="Calendario" component={Link} to="/">
			<ListItemIcon>
				<EventIcon />
			</ListItemIcon>
			<ListItemText primary="Calendario" />
		</ListItem>
	</div>
);

export const adminListItems = (
	<div>
		<ListSubheader inset>Administrador</ListSubheader>
		<ListItem button key="Eventos" component={Link} to="/events">
			<ListItemIcon>
				<EventNoteIcon />
			</ListItemIcon>
			<ListItemText primary="Eventos" />
		</ListItem>
		<ListItem button key="Calendarios" component={Link} to="/calendars">
			<ListItemIcon>
				<EventAvailableIcon />
			</ListItemIcon>
			<ListItemText primary="Calendarios" />
		</ListItem>
		<ListItem button key="Users" component={Link} to="/users">
			<ListItemIcon>
				<PeopleIcon />
			</ListItemIcon>
			<ListItemText primary="Usuarios" />
		</ListItem>
	</div>
);
