import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Button from '@material-ui/core/Button';
import './index.css';
import Spinner from '../Spinner';
import axios from 'axios';
import { useSession } from '../Session';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AlertText from '../AlertText';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import SaveIcon from '@material-ui/icons/Save';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function stableSort(array, cmp) {
	const stabilizedThis = array.map((el, index) => [ el, index ]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
	{ id: 'displayName', numeric: false, label: 'Nombre', colSpan: 1 },
	{ id: 'email', numeric: false, label: 'Correo electronico', colSpan: 1 },
	{ id: 'role', numeric: false, label: 'Rol', colSpan: 1 },
	{ id: 'lastSignInTime', numeric: false, label: 'Ultima conexion', colSpan: 1 },
	{ id: 'actions', numeric: false, label: 'Acciones', colSpan: 2 }
];

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: theme.spacing(4)
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	table: {
		minWidth: 750
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	},
	headerStyle: {
		display: 'flex',
		justifyContent: 'center',
		padding: theme.spacing(4, 0)
	},
	button: {
		color: '#fff',
		backgroundColor: '#BECD00',
		'&:hover, &:focus': {
			backgroundColor: '#BECD00'
		},
		margin: theme.spacing(3, 3)
	},
	appBar: {
		position: 'relative',
		background: '#BECD00'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	form: {
		margin: theme.spacing(6, 0)
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	}
}));

function useUsers(refresh) {
	const [ users, setUsers ] = useState([]);
	const [ token, setToken ] = useState('');
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState('');
	const user = useSession();

	useEffect(
		() => {
			const fetchData = async () => {
				const idTokenResult = await user.getIdTokenResult().then((tokenResult) => {
					setToken(tokenResult.token);
					return tokenResult.token;
				});
				axios.defaults.headers.common = { Authorization: `Bearer ${idTokenResult}` };
				setLoading(true);
				const users = await axios
					.get(process.env.REACT_APP_FIREBASE_API + '/users')
					.then(function(response) {
						// handle success
						setLoading(false);
						setUsers(response.data.users);
					})
					.catch(function(error) {
						// handle error
						setLoading(false);
						setError(error);
					});
			};
			fetchData();
		},
		[ refresh ]
	);

	return { token, users, loading, error };
}

function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align="left"
						padding="default"
						colSpan={headCell.colSpan}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf([ 'asc', 'desc' ]).isRequired,
	orderBy: PropTypes.string.isRequired
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function EnhancedTable() {
	const classes = useStyles();
	const [ order, setOrder ] = React.useState('asc');
	const [ orderBy, setOrderBy ] = React.useState('calories');
	const [ selected, setSelected ] = React.useState([]);
	const [ page, setPage ] = React.useState(0);
	const [ dense, setDense ] = React.useState(false);
	const [ rowsPerPage, setRowsPerPage ] = React.useState(5);
	/* Variables dialog  */
	const [ openUpdateDialog, setOpenUpdateDialog ] = React.useState(false);
	const [ openCreateDialog, setOpenCreateDialog ] = React.useState(false);
	/* Variables alert */
	const [ openSuccess, setOpenSuccess ] = React.useState(false);
	const [ openAlert, setOpenAlert ] = React.useState(false);
	const [ alertText, setAlertText ] = React.useState('');
	/* Variables calendar */
	const [ refresh, setRefresh ] = React.useState(false);
	const { token, users, loading, error } = useUsers(refresh);
	const [ displayName, setDisplayName ] = React.useState('');
	const [ email, setEmail ] = React.useState('');
	const [ role, setRole ] = React.useState('');
	const [ password, setPassword ] = React.useState('');
	const [ uid, setUid ] = React.useState(null);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = users.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

	/* Alert actions */

	const handleCloseSuccessAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSuccess(false);
	};

	const handleCloseErrorAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAlert(false);
	};

	/* Dialog actions */

	const hanleOpenUpdateUser = (uid) => {
		const findUser = users.find((user) => user.uid === uid);

		if (findUser) {
			setDisplayName(findUser.displayName);
			setEmail(findUser.email);
			setRole(findUser.role);
			setUid(findUser.uid);
			setOpenUpdateDialog(true);
		}
	};

	const hanleCloseUpdateUser = () => {
		setOpenUpdateDialog(false);
	};

	const hanleOpenCreateUser = () => {
		setOpenCreateDialog(true);
	};

	const hanleCloseCreateUser = () => {
		setOpenCreateDialog(false);
	};

	const onCreate = async () => {
		if (displayName !== '' && email !== '' && role !== '' && password !== '') {
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			await axios
				.post(process.env.REACT_APP_FIREBASE_API + '/users', {
					displayName: displayName,
					password: password,
					email: email,
					role: role
				})
				.then(function(response) {
					// handle success
					setDisplayName('');
					setEmail('');
					setRole('');
					setPassword('');
					setAlertText('El usuario se ha creado correctamente!');
					setOpenSuccess(true);
					setOpenCreateDialog(false);
					window.location.reload();
				})
				.catch(function(error) {
					// handle error
					setAlertText('No se ha podido crear el usuario.');
					setOpenAlert(true);
				});
		} else {
			setAlertText('Faltan campos por rellenar.');
			setOpenAlert(true);
		}
	};

	const onUpdate = async () => {
		if (displayName !== '' && email !== '' && role !== '' && uid !== null && password !== '') {
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			await axios
				.patch(process.env.REACT_APP_FIREBASE_API + '/users/' + uid, {
					displayName: displayName,
					password: password,
					email: email,
					role: role
				})
				.then(function(response) {
					// handle success
					setDisplayName('');
					setEmail('');
					setRole('');
					setPassword('');
					setAlertText('El usuario se ha actualizado correctamente!');
					setOpenSuccess(true);
					setOpenUpdateDialog(false);
					setRefresh(!refresh);
				})
				.catch(function(error) {
					// handle error
					setAlertText('No se ha podido actualizar el usuario.');
					setOpenAlert(true);
				});
		} else {
			setAlertText('Faltan campos por rellenar.');
			setOpenAlert(true);
		}
	};

	const onDelete = async (handleUid) => {
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		await axios
			.delete(process.env.REACT_APP_FIREBASE_API + '/users/' + handleUid)
			.then(function(response) {
				// handle success
				setAlertText('El usuario se ha borrado correctamente!');
				setOpenSuccess(true);
				setRefresh(!refresh);
				console.log(refresh);
			})
			.catch(function(error) {
				// handle error
				console.log(error.message);
				setAlertText('No se ha podido borrar el usuario.');
				setOpenAlert(true);
			});
	};

	if (loading) {
		return <Spinner />;
	}

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Typography variant="h4" className={classes.headerStyle}>
					Usuarios de la plataforma
				</Typography>
				<Button
					variant="contained"
					className={classes.button}
					startIcon={<PersonAddIcon />}
					onClick={hanleOpenCreateUser}
				>
					Añadir
				</Button>
				<TableContainer>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size={dense ? 'small' : 'medium'}
						aria-label="enhanced table"
					>
						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={users.length}
						/>
						<TableBody>
							{stableSort(users, getSorting(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((user) => {
									return (
										<TableRow
											hover
											onClick={(event) => handleClick(event, user.displayName)}
											role="checkbox"
											tabIndex={-1}
											key={user.uid}
										>
											<TableCell>{user.displayName}</TableCell>
											<TableCell>{user.email}</TableCell>
											<TableCell>{user.role}</TableCell>
											<TableCell>{user.lastSignInTime}</TableCell>
											<TableCell padding="none">
												<IconButton
													aria-label="update"
													color="primary"
													onClick={() => hanleOpenUpdateUser(user.uid)}
												>
													<CreateIcon />
												</IconButton>
											</TableCell>
											<TableCell>
												<IconButton
													aria-label="delete"
													color="secondary"
													onClick={() => onDelete(user.uid)}
												>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[ 5, 10, 25 ]}
					component="div"
					count={users.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
			<Dialog fullScreen open={openUpdateDialog} onClose={hanleCloseUpdateUser} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={hanleCloseUpdateUser} aria-label="close">
							<CloseIcon />
						</IconButton>
						<Typography variant="subtitle1" className={classes.title} />

						<Button autoFocus color="inherit" onClick={onUpdate}>
							<SaveIcon style={{ marginRight: 5 }} />
							Guardar
						</Button>
					</Toolbar>
				</AppBar>
				<Grid container spacing={0} direction="column" alignItems="center" justify="center">
					<Grid item>
						<Typography variant="h4" style={{ textAlign: 'center', marginTop: 40 }}>
							Actualizar Usuario
						</Typography>
						<form className={classes.form} noValidate>
							<Typography
								variant="subtitle1"
								style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
							>
								Nombre del usuario
							</Typography>
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								id="displayName"
								label="Nombre"
								type="text"
								name="name"
								autoFocus
								value={displayName}
								onChange={(e) => {
									setDisplayName(e.target.value);
								}}
								style={{ marginBottom: 30 }}
							/>
							<Typography
								variant="subtitle1"
								style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
							>
								Correo electronico
							</Typography>
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								name="email"
								label="Correo electronico"
								type="text"
								id="color"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								style={{ marginBottom: 30 }}
							/>
							<Typography
								variant="subtitle1"
								style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
							>
								Rol del usuario
							</Typography>
							<FormControl variant="outlined" margin="normal" fullWidth className={classes.formControl}>
								<Select
									labelId="role"
									id="role"
									value={role}
									onChange={(e) => {
										setRole(e.target.value);
									}}
								>
									<MenuItem value={'user'}>Usuario</MenuItem>
									<MenuItem value={'admin'}>Administrador</MenuItem>
								</Select>
							</FormControl>
							<Typography
								variant="subtitle1"
								style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
							>
								Contraseña del usuario
							</Typography>
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								id="password"
								label="Contraseña"
								type="password"
								name="name"
								autoFocus
								value={password}
								onChange={(e) => {
									setPassword(e.target.value);
								}}
							/>
						</form>
					</Grid>
				</Grid>
			</Dialog>
			<Dialog fullScreen open={openCreateDialog} onClose={hanleCloseCreateUser} TransitionComponent={Transition}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={hanleCloseCreateUser} aria-label="close">
							<CloseIcon />
						</IconButton>
						<Typography variant="subtitle1" className={classes.title} />

						<Button autoFocus color="inherit" onClick={onCreate}>
							<AddBoxIcon style={{ marginRight: 5 }} />
							Añadir
						</Button>
					</Toolbar>
				</AppBar>
				<Grid container spacing={0} direction="column" alignItems="center" justify="center">
					<Grid item>
						<Typography variant="h4" style={{ textAlign: 'center', marginTop: 40 }}>
							Crear Usuario
						</Typography>
						<form className={classes.form} noValidate>
							<Typography
								variant="subtitle1"
								style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
							>
								Nombre del usuario
							</Typography>
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								id="displayName"
								label="Nombre"
								type="text"
								name="name"
								autoFocus
								onChange={(e) => {
									setDisplayName(e.target.value);
								}}
								style={{ marginBottom: 30 }}
							/>
							<Typography
								variant="subtitle1"
								style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
							>
								Correo electronico
							</Typography>
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								name="email"
								label="Correo electronico"
								type="text"
								id="color"
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								style={{ marginBottom: 30 }}
							/>
							<Typography
								variant="subtitle1"
								style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
							>
								Rol del usuario
							</Typography>
							<FormControl variant="outlined" margin="normal" fullWidth className={classes.formControl}>
								<Select
									labelId="role"
									id="role"
									onChange={(e) => {
										setRole(e.target.value);
									}}
								>
									<MenuItem value={'user'}>Usuario</MenuItem>
									<MenuItem value={'admin'}>Administrador</MenuItem>
								</Select>
							</FormControl>
							<Typography
								variant="subtitle1"
								style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
							>
								Contraseña del usuario
							</Typography>
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								id="password"
								label="Contraseña"
								type="password"
								name="name"
								autoFocus
								onChange={(e) => {
									setPassword(e.target.value);
								}}
							/>
						</form>
					</Grid>
				</Grid>
			</Dialog>
			<AlertText
				openSuccess={openSuccess}
				handleCloseSuccessAlert={handleCloseSuccessAlert}
				openAlert={openAlert}
				handleCloseErrorAlert={handleCloseErrorAlert}
				alertText={alertText}
			/>
		</div>
	);
}
