import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Button from '@material-ui/core/Button';
import './index.css';
import Spinner from '../Spinner';
import app from '../Firebase';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CreateIcon from '@material-ui/icons/Create';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import AlertText from '../AlertText';
import ListUsers from '../TransferList';

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function stableSort(array, cmp) {
	const stabilizedThis = array.map((el, index) => [ el, index ]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
	{ id: 'name', numeric: false, label: 'Nombre', colSpan: 1 },
	{ id: 'color', numeric: false, label: 'Color', colSpan: 1 },
	{ id: 'actions', numeric: false, label: 'Acciones', colSpan: 2 }
];

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: theme.spacing(4)
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	table: {
		minWidth: 750
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	},
	headerStyle: {
		display: 'flex',
		justifyContent: 'center',
		padding: theme.spacing(4, 0)
	},
	button: {
		color: '#fff',
		backgroundColor: '#BECD00',
		'&:hover, &:focus': {
			backgroundColor: '#BECD00'
		},
		margin: theme.spacing(3, 3)
	},
	appBar: {
		position: 'relative',
		background: '#BECD00'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	form: {
		margin: theme.spacing(6, 0)
	}
}));

function useCalendars() {
	const [ calendars, setCalendars ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState('');

	useEffect(() => {
		const fetchData = () => {
			const calendars = app.firestore().collection('calendars').onSnapshot((querySnapshot) => {
				const newCalendars = querySnapshot.docs.map((doc) => ({
					uid: doc.id,
					id: doc.data().id,
					name: doc.data().name,
					bgColor: doc.data().bgColor,
					borderColor: doc.data().borderColor,
					color: doc.data().color,
					dragBgColor: doc.data().dragBgColor,
					users: doc.data().users
				}));

				setLoading(false);
				setCalendars(newCalendars);
			});
		};
		fetchData();
	}, []);

	return { calendars, loading, error };
}

function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align="left"
						padding="default"
						colSpan={headCell.colSpan}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf([ 'asc', 'desc' ]).isRequired,
	orderBy: PropTypes.string.isRequired
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function EnhancedTable() {
	const classes = useStyles();
	/* Variables table  */
	const [ order, setOrder ] = React.useState('asc');
	const [ orderBy, setOrderBy ] = React.useState('calories');
	const [ selected, setSelected ] = React.useState([]);
	const [ page, setPage ] = React.useState(0);
	const [ rowsPerPage, setRowsPerPage ] = React.useState(5);
	/* Variables dialog  */
	const [ openUpdateDialog, setOpenUpdateDialog ] = React.useState(false);
	const [ openCreateDialog, setOpenCreateDialog ] = React.useState(false);
	/* Variables alert */
	const [ openSuccess, setOpenSuccess ] = React.useState(false);
	const [ openAlert, setOpenAlert ] = React.useState(false);
	const [ alertText, setAlertText ] = React.useState('');
	/* Variables calendar */
	const { calendars, loading, error } = useCalendars();
	const [ nom, setNom ] = React.useState('');
	const [ color, setColor ] = React.useState('');
	const [ uid, setUid ] = React.useState(null);
	const [ usuarios, setUsuarios ] = React.useState([]);

	/* Alert Table */
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, calendars.length - page * rowsPerPage);

	/* Alert actions */

	const handleCloseSuccessAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSuccess(false);
	};

	const handleCloseErrorAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAlert(false);
	};

	/* Dialog actions */

	const hanleOpenUpdateCalendar = (uid) => {
		const findCalendar = calendars.find((calendar) => calendar.uid === uid);
		
		if (findCalendar) {
			setNom(findCalendar.name);
			setColor(findCalendar.color);
			setUid(findCalendar.uid);
			setUsuarios(findCalendar.users);
			setOpenUpdateDialog(true);
		}
	};

	const hanleCloseUpdateCalendar = () => {
		setOpenUpdateDialog(false);
	};

	const hanleOpenCreateCalendar = () => {
		setOpenCreateDialog(true);
	};

	const hanleCloseCreateCalendar = () => {
		setOpenCreateDialog(false);
	};

	const onCreate = () => {
		if (nom !== '' && color !== '') {
			const listOfUserId = [...new Set(usuarios.map(it => it.uid.toString()))];
			const db = app.firestore();
			db
				.collection('calendars')
				.add({
					name: nom,
					color: color,
					bgColor: color,
					borderColor: color,
					dragBgColor: color,
					users: listOfUserId
				})
				.then(function(docRef) {
					//db.collection('calendars').doc(docRef.id).collection('schedule').add('1289')
					setNom('');
					setColor('');
					setUid(null);
					setUsuarios([]);
					setAlertText('El calendario se ha creado correctamente!');
					setOpenSuccess(true);
					setOpenCreateDialog(false);
				})
				.catch(function(error) {
					setAlertText('No se ha podido crear el calendario.');
					setOpenAlert(true);
				});
		} else {
			setAlertText('Faltan campos por rellenar.');
			setOpenAlert(true);
		}
	};

	const onUpdate = () => {
		console.log(uid)
		if (nom !== '' && color !== '' && uid !== null) {
			const listOfUserId = [...new Set(usuarios.map(it => it.uid.toString()))];
			const db = app.firestore();
			db
				.collection('calendars')
				.doc(uid)
				.set({
					color: color,
					bgColor: color,
					borderColor: color,
					dragBgColor: color,
					name: nom,
					users: listOfUserId
				})
				.then(function() {
					setAlertText('El calendario se ha actualizado correctamente!');
					setOpenSuccess(true);
					setOpenUpdateDialog(false);
					setNom('');
					setColor('');
					setUid(null);
					setUsuarios([]);
				})
				.catch(function(error) {
					setAlertText('No se ha podido actualizar el calendario.');
					setOpenAlert(true);
				});
		} else {
			setAlertText('Faltan campos por rellenar.');
			setOpenAlert(true);
		}
	};

	const onDelete = (handleUid) => {
		const db = app.firestore();
		db
			.collection('calendars')
			.doc(handleUid)
			.delete()
			.then(function() {
				setAlertText('El calendario se ha borrado correctamente!');
				setOpenSuccess(true);
			})
			.catch(function(error) {
				setAlertText('No se ha podido borrar el calendario.');
				setOpenAlert(true);
			});
	};

	const myCallback = (dataFromChild) => {
		setUsuarios(dataFromChild);
		console.log(dataFromChild);
    }

	if (loading) {
		return (
			<Spinner />
		);
	}

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Typography variant="h4" className={classes.headerStyle}>
					Calendarios de la plataforma
				</Typography>
				<Button
					variant="contained"
					className={classes.button}
					startIcon={<PersonAddIcon />}
					onClick={hanleOpenCreateCalendar}
				>
					Añadir
				</Button>
				<TableContainer>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size={'medium'}
						aria-label="enhanced table"
					>
						<EnhancedTableHead
							classes={classes}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={calendars.length}
						/>
						<TableBody>
							{stableSort(calendars, getSorting(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((calendar) => {
									return (
										<TableRow
											hover
											onClick={(event) => handleClick(event, calendar.name)}
											role="checkbox"
											tabIndex={-1}
											key={calendar.uid}
										>
											<TableCell>{calendar.name}</TableCell>
											<TableCell>{calendar.color}</TableCell>
											<TableCell padding="none">
												<IconButton
													aria-label="update"
													color="primary"
													onClick={() => hanleOpenUpdateCalendar(calendar.uid)}
												>
													<CreateIcon />
												</IconButton>
											</TableCell>
											<TableCell>
												<IconButton
													aria-label="delete"
													color="secondary"
													onClick={() => onDelete(calendar.uid)}
												>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: 53 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[ 5, 10, 25 ]}
					component="div"
					count={calendars.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
			<Dialog
				fullScreen
				open={openUpdateDialog}
				onClose={hanleCloseUpdateCalendar}
				TransitionComponent={Transition}
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={hanleCloseUpdateCalendar} aria-label="close">
							<CloseIcon />
						</IconButton>
						<Typography variant="subtitle1" className={classes.title} />

						<Button autoFocus color="inherit" onClick={onUpdate}>
							<SaveIcon style={{ marginRight: 5 }} />
							Guardar
						</Button>
					</Toolbar>
				</AppBar>
				<Grid container spacing={0} direction="column" alignItems="center" justify="center">
					<Grid item>
						<Typography variant="h4" style={{ textAlign: 'center', marginTop: 40 }}>
							Actualizar Calendario
						</Typography>
						<form className={classes.form} noValidate>
							<Typography
								variant="subtitle1"
								style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
							>
								Nombre del calendario
							</Typography>
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								id="name"
								label="Nombre"
								type="text"
								name="name"
								autoFocus
								value={nom}
								onChange={(e) => {
									setNom(e.target.value);
								}}
								style={{ marginBottom: 30 }}
							/>
							<Typography
								variant="subtitle1"
								style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
							>
								Color utilizado
							</Typography>
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								name="color"
								label="Color"
								type="text"
								id="color"
								value={color}
								onChange={(e) => {
									setColor(e.target.value);
								}}
								style={{ marginBottom: 30 }}
							/>
							<Typography
								variant="subtitle1"
								style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
							>
								Usuarios Asignados
							</Typography>
							<ListUsers aUsers={usuarios} callbackFromParent={myCallback}/>
						</form>
					</Grid>
				</Grid>
			</Dialog>
			<Dialog
				fullScreen
				open={openCreateDialog}
				onClose={hanleCloseCreateCalendar}
				TransitionComponent={Transition}
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton edge="start" color="inherit" onClick={hanleCloseCreateCalendar} aria-label="close">
							<CloseIcon />
						</IconButton>
						<Typography variant="subtitle1" className={classes.title} />

						<Button autoFocus color="inherit" onClick={onCreate}>
							<AddBoxIcon style={{ marginRight: 5 }} />
							Añadir
						</Button>
					</Toolbar>
				</AppBar>
				<Grid container spacing={0} direction="column" alignItems="center" justify="center">
					<Grid item>
						<Typography variant="h4" style={{ textAlign: 'center', marginTop: 40 }}>
							Crear Calendario
						</Typography>
						<form className={classes.form} noValidate>
							<Typography
								variant="subtitle1"
								style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
							>
								Nombre del calendario
							</Typography>
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								id="name"
								label="Nombre"
								type="text"
								name="name"
								autoFocus
								onChange={(e) => {
									setNom(e.target.value);
								}}
								style={{ marginBottom: 30 }}
							/>
							<Typography
								variant="subtitle1"
								style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
							>
								Color utilizado
							</Typography>
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								name="color"
								label="Color"
								type="text"
								id="color"
								onChange={(e) => {
									setColor(e.target.value);
								}}
								style={{ marginBottom: 30 }}
							/>
							<Typography
								variant="subtitle1"
								style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
							>
								Usuarios Asignados
							</Typography>
							<ListUsers aUsers={usuarios} callbackFromParent={myCallback}/>
						</form>
					</Grid>
				</Grid>
			</Dialog>
			<AlertText
				openSuccess={openSuccess}
				handleCloseSuccessAlert={handleCloseSuccessAlert}
				openAlert={openAlert}
				handleCloseErrorAlert={handleCloseErrorAlert}
				alertText={alertText}
			/>
		</div>
	);
}
