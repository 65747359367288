import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import ScreenImg from '../../assets/images/screen-login.jpg';
import LogoImg from '../../assets/images/sm-logo.jpg';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from '../layout/Copyright';
import Alert from '@material-ui/lab/Alert';

import { useSession, loginWithEmail } from '../Session';
import { Redirect } from 'react-router-dom';

import * as ROUTES from '../../constants/Routes';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh'
	},
	image: {
		backgroundImage: `url(${ScreenImg})`,
		backgroundRepeat: 'no-repeat',
		backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
		backgroundSize: 'cover',
		backgroundPosition: 'center'
	},
	paper: {
		margin: theme.spacing(22, 14),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(8, 4)
		},
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: '#515CE6',
		'&:hover, &:focus': {
			backgroundColor: '#515CE6'
		}
	},
	cover: {
	  width: 90,
	  height: 90,
	  marginBottom: theme.spacing(3)
	},
	title: {
		fontFamily: 'Fredericka the Great',
		fontSize: '2.5em'
	}
}));

const SignIn = () => {
	const classes = useStyles();
	const user = useSession();
	const [ error, setError ] = useState('');

	const handleLogin = async (e) => {
		e.preventDefault();
		const { email, password } = e.target.elements;
		try {
			await loginWithEmail(email.value, password.value);
		} catch (err) {
			setError(err.message || 'Please try again.');
		}
	};

	if (user) {
		return <Redirect to={ROUTES.LANDING} />;
	}

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={8} className={classes.image} />
			<Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
				<div className={classes.paper}>
					<CardMedia
						className={classes.cover}
						image={LogoImg}
						title="Sistemas medioambientals"
					/>
					<Typography component="h1" variant="h5" className={classes.title}>
						Inicio de sesión
					</Typography>
					<form className={classes.form} onSubmit={handleLogin} noValidate>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Correo electrónico"
							type="email"
							name="email"
							autoComplete="email"
							autoFocus
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Contraseña"
							type="password"
							id="password"
							autoComplete="current-password"
						/>
						<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
							Iniciar sesión
						</Button>

						<Box mt={5}>
							{error && <Alert severity="error">{error}</Alert>}
							<br />
							<Copyright />
						</Box>
					</form>
				</div>
			</Grid>
		</Grid>
	);
};

export default SignIn;
