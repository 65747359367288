import React, { useState, useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import BadgeAvatars from './BadgeAvatars';
import { CustomMenu } from './CustomMenu';
import { Navigation } from '../Navigation/index';
import { useSession } from '../Session';
import EventCalendarContainer from './EventCalendarContainer';
import * as ROUTES from '../../constants/Routes';
import UserList from '../Users';
import EventCalendarAdmin from '../Events';
import CalendarList from '../Calendars';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex'
	},
	toolbar: {
		paddingRight: 24 // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		background: '#BECD00'
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	menuButton: {
		marginRight: 36
	},
	menuButtonHidden: {
		display: 'none'
	},
	title: {
		flexGrow: 1
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9)
		}
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto'
	},
	container: {
		padding: theme.spacing(0)
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column'
	},
	fixedHeight: {
		height: 240
	}
}));

const Dashboard = () => {
	const classes = useStyles();
	const [ open, setOpen ] = React.useState(true);
	const user = useSession();
	const [ admin, setAdmin ] = useState(false);
	const [ loading, setLoading ] = useState(true);
	useEffect(() => {
		setLoading(true);
		const fetchData = async () => {
			const idTokenResult = await user
				.getIdTokenResult()
				.then((idTokenResult) => {
					// Confirm the user is an Admin.
					if (idTokenResult.claims.role === 'admin') {
						// Show admin UI.
						setAdmin(true);
						setLoading(false);
					}else{
						setAdmin(false);
						setLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		};
		fetchData();
	}, []);

	const handleDrawerOpen = () => {
		setOpen(true);
	};
	const handleDrawerClose = () => {
		setOpen(false);
	};

	if (loading) {
		return null;
	}

	if (admin === true) {
		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
					<Toolbar className={classes.toolbar}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
						>
							<MenuIcon />
						</IconButton>
						<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
							{user.displayName}
						</Typography>
						<CustomMenu>
							<BadgeAvatars user={user} />
						</CustomMenu>
					</Toolbar>
				</AppBar>
				<Navigation open={open} handleDrawerClose={handleDrawerClose} admin={admin} />
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<Container maxWidth="xl" className={classes.container}>
						<Grid>
							<Switch>
								<Route exact path={ROUTES.USERS} component={UserList} />
								<Route exact path={ROUTES.EVENTS} component={EventCalendarAdmin} />
								<Route exact path={ROUTES.CALENDARS} component={CalendarList} />
								<Redirect to={ROUTES.EVENTS} />
							</Switch>
						</Grid>
					</Container>
				</main>
			</div>
		);
	} else {
		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
					<Toolbar className={classes.toolbar}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
						>
							<MenuIcon />
						</IconButton>
						<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
							{user.displayName}
						</Typography>
						<CustomMenu>
							<BadgeAvatars user={user} />
						</CustomMenu>
					</Toolbar>
				</AppBar>
				<Navigation open={open} handleDrawerClose={handleDrawerClose} admin={admin} />
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<Container maxWidth="xl" className={classes.container}>
						<Grid>
							<Switch>
								<Route exact path={ROUTES.LANDING} component={EventCalendarContainer} />
								<Redirect to={ROUTES.LANDING} />
							</Switch>
						</Grid>
					</Container>
				</main>
			</div>
		);
	}
};

export default Dashboard;
