import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Calendar from '@toast-ui/react-calendar';
import 'tui-calendar/dist/tui-calendar.css';
import app from '../Firebase';
import Spinner from '../Spinner';
import Fab from '@material-ui/core/Fab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforetIcon from '@material-ui/icons/NavigateBefore';
import CalendarTodaytIcon from '@material-ui/icons/CalendarToday';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AlertText from '../AlertText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import './index.css';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TabsComponent from '../Tabs';

const useStyles = makeStyles((theme) => ({
	eventConainer: {
		width: '100%',
		minWidth: 800,
		height: '100vh',
		padding: theme.spacing(4, 0)
	},
	classHeightCalendar: {
		height: 800
	},
	navCalendar: {
		margin: theme.spacing(2, 0, 4, 0)
	},
	navElementCalendar: {
		marginRight: theme.spacing(2),
		backgroundColor: '#BECD00',
		'&:hover, &:focus': {
			backgroundColor: '#BECD00'
		}
	},
	navElementCalendarToday: {
		backgroundColor: '#BECD00',
		'&:hover, &:focus': {
			backgroundColor: '#BECD00'
		},
		margin: theme.spacing(0, 2, 0, 4)
	},
	extendedIcon: {
		marginRight: theme.spacing(1)
	},
	monthView: {
		backgroundColor: '#515CE6',
		'&:hover, &:focus': {
			backgroundColor: '#515CE6'
		},
		color: 'white',
		marginRight: theme.spacing(4)
	},
	appBar: {
		position: 'relative',
		background: '#BECD00'
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1
	},
	form: {
		margin: 'auto',
		maxWidth: 500
	},
	inputFormUpdate: {
		textAlign: 'center',
		width: '100%',
		margin: 'auto',
		justifyContent: 'center',
		paddingTop: theme.spacing(1)
	}
}));

function useEvents(clientId = null) {
	const [ events, setEvents ] = useState([]);
	const [ calendars, setCalendars ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState('');

	useEffect(
		() => {
			setLoading(true);
			const unsubscribe = () => {
				const ref = app.firestore().collection('calendars');
				const eventsCollection = ref.get().then((querySnapshotRef) => {
					const calendars = [];
					var newEvents = [];
					querySnapshotRef.forEach(function(document) {
						const calendar = document.data();
						calendar.id = document.id;
						calendars.push(calendar);
						if (document.id === clientId || clientId === null) {
							document.ref.collection('schedule').get().then((querySnapshot) => {
								const eventsDoc = querySnapshot.docs.map((doc) => ({
									id: doc.id,
									calendarId: doc.ref.parent.parent.id,
									title: doc.data().title,
									start: doc.data().start.toDate(),
									end: doc.data().end.toDate(),
									category: doc.data().category,
									adress: doc.data().adress,
									diffusion: doc.data().diffusion,
									district: doc.data().district,
									emailOfContact: doc.data().emailOfContact,
									entity: doc.data().entity,
									featuresGroup: doc.data().featuresGroup,
									inscriptions: doc.data().inscriptions,
									kindOfGroup: doc.data().kindOfGroup,
									level: doc.data().level,
									nameOfContact: doc.data().nameOfContact,
									participants: doc.data().participants,
									remark: doc.data().remark,
									phoneOfContact: doc.data().phoneOfContact,
									dateEmailFirst: doc.data().dateEmailFirst.toDate(),
									dateEmailSecond: doc.data().dateEmailSecond.toDate()
								}));
								
								newEvents = [ ...newEvents, ...eventsDoc ];
								setEvents(newEvents);
							});
						}
					});
					setLoading(false);
					setCalendars(calendars);
				});
			};
			unsubscribe();
		},
		[ clientId ]
	);

	return { events, calendars, loading, error };
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const EventCalendarAdmin = () => {
	const classes = useStyles();
	const myTheme = {
		'common.border': '1px solid #e5e5e5',
		'common.backgroundColor': 'white',
		'common.holiday.color': '#ff4040',
		'common.saturday.color': '#333',
		'common.dayname.color': '#333',
		'common.today.color': '#333',
		// month header 'dayname'
		'month.dayname.height': '39px',
		'month.dayname.borderLeft': '1px solid #e5e5e5',
		'month.dayname.paddingLeft': '10px',
		'month.dayname.paddingRight': '10px',
		'month.dayname.backgroundColor': 'inherit',
		'month.dayname.fontSize': '15px',
		'month.dayname.fontWeight': 'normal',
		'month.dayname.textAlign': 'left',

		// month day grid cell 'day'
		'month.holidayExceptThisMonth.color': 'rgba(255, 64, 64, 0.4)',
		'month.dayExceptThisMonth.color': 'rgba(51, 51, 51, 0.4)',
		'month.weekend.backgroundColor': '#fafafa',
		'month.day.fontSize': '14px'
	};
	const initialMonth = new Date();
	moment.updateLocale('pl', {
		months: [
			'ENERO',
			'FEBRERO',
			'MARZO',
			'ABRIL',
			'MAYO',
			'JUNIO',
			'JULIO',
			'AGOSTO',
			'SEPTIEMBRE',
			'OCTUBRE',
			'NOVIEMBRE',
			'DICIEMBRE'
		]
	});

	/* */
	const [ client, setClient ] = React.useState('');
	const [ clientId, setClientId ] = React.useState(null);
	const { events, calendars, loading, error } = useEvents(clientId);

	const [ info, setInfo ] = React.useState([]);
	/* Variables dialog  */
	const [ openUpdateDialog, setOpenUpdateDialog ] = React.useState(false);
	const [ openCreateDialog, setOpenCreateDialog ] = React.useState(false);
	/* Variables alert */
	const [ openSuccess, setOpenSuccess ] = React.useState(false);
	const [ openAlert, setOpenAlert ] = React.useState(false);
	const [ alertText, setAlertText ] = React.useState('');
	/* Variables calendar */
	const [ month, setMonth ] = React.useState(moment(initialMonth).format('MMMM YYYY'));
	const [ day, setDay ] = React.useState(moment(initialMonth).format('DD MMMM'));
	const refContainer = useRef(null);
	/* Create event */
	const [ adress, setAdress ] = React.useState('');
	const [ category, setCategory ] = React.useState('time');
	const [ dateEmailFirst, setDateEmailFirst ] = React.useState('');
	const [ dateEmailSecond, setDateEmailSecond ] = React.useState('');
	const [ diffusion, setDiffusion ] = React.useState('');
	const [ district, setDistrict ] = React.useState('');
	const [ emailOfContact, setEmailOfContact ] = React.useState('');
	const [ end, setEnd ] = React.useState('');
	const [ entity, setEntity ] = React.useState('');
	const [ featuresGroup, setFeaturesGroup ] = React.useState('');
	const [ inscriptions, setInscriptions ] = React.useState(false);
	const [ kindOfGroup, setKindOfGroup ] = React.useState('');
	const [ level, setLevel ] = React.useState('');
	const [ nameOfContact, setNameOfContact ] = React.useState('');
	const [ participants, setParticipants ] = React.useState(0);
	const [ phoneOfContact, setPhoneOfContact ] = React.useState('');
	const [ remark, setRemark ] = React.useState('');
	const [ title, setTitle ] = React.useState('');
	const [ start, setStart ] = React.useState('');

	/* Alert actions */

	const handleCloseSuccessAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSuccess(false);
	};

	const handleOpenSuccessAlert = () => {
		setOpenSuccess(true);
	};

	const handleCloseErrorAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenAlert(false);
	};

	const handleOpenErrorAlert = () => {
		setOpenAlert(true);
	};

	const handleSetTextAlert = (text) => {
		setAlertText(text);
	};

	const handleClickNextButton = () => {
		const calendarInstance = refContainer.current.getInstance();
		setMonth(moment(refContainer.current.getInstance().getDateRangeEnd().toDate()).format('MMMM YYYY'));
		calendarInstance.next();
	};

	const handleClickBefore = () => {
		const calendarInstance = refContainer.current.getInstance();
		setMonth(
			moment(refContainer.current.getInstance().getDate().toDate()).subtract(1, 'months').format('MMMM YYYY')
		);
		calendarInstance.prev();
	};

	const handleClickToday = () => {
		const calendarInstance = refContainer.current.getInstance();
		setMonth(moment(calendarInstance.today()).format('MMMM YYYY'));
		calendarInstance.today();
	};

	const handleChange = (ev) => {
		setClient(ev.target.value);
		ev.target.value === '' ? setClientId(null) : setClientId(ev.target.value);
	};

	/* Dialog actions */

	const hanleCloseCreateCalendar = () => {
		setOpenCreateDialog(false);
	};

	const hanleCloseUpdateCalendar = () => {
		setOpenUpdateDialog(false);
	};

	const handleClickOpenCalendar = (ev) => {
		const event = events.find((event) => event.id === ev.schedule.id);
		setInfo(event);
		setOpenUpdateDialog(true);
	};

	const handleClickDayname = (ev) => {
		const date = new Date(ev.start);
		const dateMoment = moment(date).format('YYYY-MM-DDThh:mm');
		setStart(dateMoment);
		setOpenCreateDialog(true);
	};

	const onCreate = () => {
		if (start !== '' && dateEmailFirst !== '' && dateEmailSecond !== '' && clientId !== null) {
			const db = app.firestore();
			db
				.collection('calendars')
				.doc(clientId)
				.collection('schedule')
				.add({
					adress: adress,
					category: category,
					dateEmailFirst: new Date(dateEmailFirst),
					dateEmailSecond: new Date(dateEmailSecond),
					diffusion: diffusion,
					district: district,
					emailOfContact: emailOfContact,
					end: new Date(start),
					entity: entity,
					featuresGroup: featuresGroup,
					inscriptions: inscriptions,
					kindOfGroup: kindOfGroup,
					level: level,
					nameOfContact: nameOfContact,
					participants: participants,
					phoneOfContact: phoneOfContact,
					remark: remark,
					title: title,
					start: new Date(start)
				})
				.then(function() {
					handleSetTextAlert('El calendario se ha actualizado correctamente!');
					handleOpenSuccessAlert(true);
					setAdress('');
					setDateEmailFirst('');
					setDateEmailSecond('');
					setDiffusion('');
					setDistrict('');
					setEmailOfContact('');
					setEnd('');
					setEntity('');
					setFeaturesGroup('');
					setInscriptions(false);
					setKindOfGroup('');
					setLevel('');
					setNameOfContact('');
					setParticipants(0);
					setPhoneOfContact('');
					setRemark('');
					setTitle('');
					setStart('');
					setOpenCreateDialog(false);
					setClientId(null);
					setClient('');
				})
				.catch(function(error) {
					handleSetTextAlert('No se ha podido actualizar el calendario.');
					handleOpenErrorAlert(true);
				});
		} else {
			if (clientId == null) {
				handleSetTextAlert('No se ha escogido un calendario para poder asignar el evento.');
				handleOpenErrorAlert(true);
			} else {
				handleSetTextAlert('Faltan campos por rellenar.');
				handleOpenErrorAlert(true);
			}
		}
	};

	if (loading) {
		return <Spinner />;
	}

	return (
		<Paper elevation={3} className={classes.eventConainer}>
			<div className={classes.navCalendar}>
				<Fab
					variant="extended"
					color="primary"
					aria-label="add"
					className={classes.navElementCalendarToday}
					onClick={handleClickToday}
				>
					<CalendarTodaytIcon className={classes.extendedIcon} />
					Hoy - {day}
				</Fab>
				<Fab
					color="primary"
					size="medium"
					aria-label="before"
					onClick={handleClickBefore}
					className={classes.navElementCalendar}
				>
					<NavigateBeforetIcon />
				</Fab>
				<Fab
					color="primary"
					size="medium"
					aria-label="next"
					onClick={handleClickNextButton}
					className={classes.navElementCalendar}
				>
					<NavigateNextIcon />
				</Fab>
				<Fab variant="extended" color="primary" aria-label="add" className={classes.monthView}>
					{month}
				</Fab>

				<FormControl>
					<InputLabel shrink id="demo-simple-select-placeholder-label-label">
						Calendarios
					</InputLabel>
					<Select
						labelId="demo-simple-select-placeholder-label-label"
						id="demo-simple-select-placeholder-label"
						value={client}
						onChange={handleChange}
						displayEmpty
						className={classes.selectEmpty}
					>
						<MenuItem value="">
							<em>Todos los clientes</em>
						</MenuItem>
						{calendars.map((calendar) => (
							<MenuItem key={calendar.id} value={calendar.id}>
								{calendar.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			<Calendar
				ref={refContainer}
				usageStatistics={false}
				calendars={calendars}
				view={'month'}
				height={800}
				disableDblClick={false}
				disableClick={false}
				isReadOnly={false}
				month={{
					daynames: [ 'Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab' ],
					startDayOfWeek: 1
				}}
				schedules={events}
				scheduleView={false}
				taskView={false}
				theme={myTheme}
				timezones={[
					{
						timezoneOffset: -60,
						displayLabel: 'GMT+01:00',
						tooltip: 'Madrid'
					}
				]}
				immediately={true}
				useDetailPopup={false}
				useCreationPopup={false}
				onClickSchedule={handleClickOpenCalendar}
				onBeforeCreateSchedule={handleClickDayname}
				week={{
					startDayOfWeek: 1,
					daynames: [ 'Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab' ],
					showTimezoneCollapseButton: true,
					timezonesCollapsed: true
				}}
			/>
			<Dialog
				fullScreen
				open={openUpdateDialog}
				onClose={hanleCloseUpdateCalendar}
				TransitionComponent={Transition}
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<Typography variant="subtitle1" className={classes.title} />
						<IconButton edge="start" color="inherit" onClick={hanleCloseUpdateCalendar} aria-label="close">
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<Grid container spacing={0}>
					<TabsComponent
						event={info}
						handleOpenSuccessAlert={handleOpenSuccessAlert}
						handleOpenErrorAlert={handleOpenErrorAlert}
						handleSetTextAlert={handleSetTextAlert}
						hanleCloseUpdateCalendar={hanleCloseUpdateCalendar}
					/>
				</Grid>
			</Dialog>
			<Dialog
				fullScreen
				open={openCreateDialog}
				onClose={hanleCloseCreateCalendar}
				TransitionComponent={Transition}
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<Typography variant="subtitle1" className={classes.title} />
						<IconButton edge="start" color="inherit" onClick={hanleCloseCreateCalendar} aria-label="close">
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<Grid container spacing={0}>
					<form className={classes.form} noValidate>
						<Typography variant="h4" style={{ textAlign: 'center', marginTop: 40, marginBottom: 30 }}>
							Crear Evento
						</Typography>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							Nombre del evento
						</Typography>
						<TextField
							variant="outlined"
							margin="normal"
							fullWidth
							id="title"
							label="Nombre"
							type="text"
							name="title"
							autoFocus
							value={title}
							onChange={(e) => {
								setTitle(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							Fecha y Hora del evento
						</Typography>
						<TextField
							variant="outlined"
							id="start"
							margin="normal"
							fullWidth
							label="Fecha y Hora"
							type="datetime-local"
							InputLabelProps={{
								shrink: true
							}}
							value={start}
							onChange={(e) => {
								setStart(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							Participantes del evento
						</Typography>
						<TextField
							variant="outlined"
							id="participants"
							margin="normal"
							fullWidth
							label="Participantes"
							type="number"
							value={participants}
							onChange={(e) => {
								setParticipants(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							¿Permiten inscriptiones?
						</Typography>
						<FormControlLabel
							margin="normal"
							id="incripciones"
							className={classes.inputFormUpdate}
							control={
								<Switch
									checked={inscriptions}
									onChange={(e) => {
										setInscriptions(!inscriptions);
									}}
									value={true}
								/>
							}
							label="Incripciones"
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							¿Como piensan hacer la difusión?
						</Typography>
						<TextField
							variant="outlined"
							id="diffusion"
							margin="normal"
							fullWidth
							label="Difusión"
							type="text"
							value={diffusion}
							onChange={(e) => {
								setDiffusion(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							Nombre del grupo, entidad o equipamineto
						</Typography>
						<TextField
							variant="outlined"
							id="entity"
							margin="normal"
							fullWidth
							label="Entidad"
							type="text"
							value={entity}
							onChange={(e) => {
								setEntity(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							Distrito
						</Typography>
						<TextField
							variant="outlined"
							id="district"
							margin="normal"
							fullWidth
							label="Distrito"
							type="text"
							value={district}
							onChange={(e) => {
								setDistrict(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							Tipo de grupo
						</Typography>
						<TextField
							variant="outlined"
							id="kindOfGroup"
							margin="normal"
							fullWidth
							label="Tipo de grupo"
							type="text"
							value={kindOfGroup}
							onChange={(e) => {
								setKindOfGroup(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							Caracteristicas del grupo
						</Typography>
						<TextField
							variant="outlined"
							id="featuresGroup"
							margin="normal"
							fullWidth
							label="Caracteristicas"
							type="text"
							value={featuresGroup}
							onChange={(e) => {
								setFeaturesGroup(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							Nivel del taller
						</Typography>
						<TextField
							variant="outlined"
							id="level"
							margin="normal"
							fullWidth
							label="Nivel"
							type="text"
							value={level}
							onChange={(e) => {
								setLevel(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							Nombre i apellidos de la persona de contacto
						</Typography>
						<TextField
							variant="outlined"
							id="nameOfContact"
							margin="normal"
							fullWidth
							label="Persona de contacto"
							type="text"
							value={nameOfContact}
							onChange={(e) => {
								setNameOfContact(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							Telèfono de la persona de contacto
						</Typography>
						<TextField
							variant="outlined"
							id="phoneOfContact"
							margin="normal"
							fullWidth
							label="Telèfono"
							type="text"
							value={phoneOfContact}
							onChange={(e) => {
								setPhoneOfContact(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							Correo electrónico de la persona de contacto
						</Typography>
						<TextField
							variant="outlined"
							id="emailOfContact"
							margin="normal"
							fullWidth
							label="Telèfono"
							type="text"
							value={emailOfContact}
							onChange={(e) => {
								setEmailOfContact(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							Lugar y dirección de realización del taller
						</Typography>
						<TextField
							variant="outlined"
							id="adress"
							margin="normal"
							fullWidth
							label="Lugar y dirección"
							type="text"
							value={adress}
							onChange={(e) => {
								setAdress(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							Observaciones
						</Typography>
						<TextField
							variant="outlined"
							id="remark"
							margin="normal"
							fullWidth
							label="Observaciones"
							type="text"
							value={remark}
							onChange={(e) => {
								setRemark(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							Fecha primer email recordatorio
						</Typography>
						<TextField
							variant="outlined"
							id="dateEmailFirst"
							margin="normal"
							fullWidth
							label="Fecha y Hora"
							type="datetime-local"
							InputLabelProps={{
								shrink: true
							}}
							value={dateEmailFirst}
							onChange={(e) => {
								setDateEmailFirst(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Typography
							variant="subtitle1"
							style={{ textAlign: 'center', fontSize: '1.2em', color: '#7d7d7d' }}
						>
							Fecha segundo email recordatorio
						</Typography>
						<TextField
							variant="outlined"
							id="dateEmailSecond"
							margin="normal"
							fullWidth
							label="Fecha y Hora"
							type="datetime-local"
							InputLabelProps={{
								shrink: true
							}}
							value={dateEmailSecond}
							onChange={(e) => {
								setDateEmailSecond(e.target.value);
							}}
							style={{ marginBottom: 30 }}
						/>
						<Button
							variant="contained"
							color="primary"
							className={classes.button}
							startIcon={<AddBoxIcon />}
							onClick={onCreate}
							style={{ width: '100%', marginBottom: 30 }}
						>
							Crear
						</Button>
					</form>
				</Grid>
			</Dialog>
			<AlertText
				openSuccess={openSuccess}
				handleCloseSuccessAlert={handleCloseSuccessAlert}
				openAlert={openAlert}
				handleCloseErrorAlert={handleCloseErrorAlert}
				alertText={alertText}
			/>
		</Paper>
	);
};

export default EventCalendarAdmin;
