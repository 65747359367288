import React from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { userListItems, adminListItems } from '../Dashboard/ListItems';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	toolbar: {
		paddingRight: 24 // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9)
		}
	}
}));

const NavigationAdmin = ({ classes, open, handleDrawerClose }) => (
	<Drawer
		variant="permanent"
		classes={{
			paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
		}}
		open={open}
	>
		<div className={classes.toolbarIcon}>
			<IconButton onClick={handleDrawerClose}>
				<ChevronLeftIcon />
			</IconButton>
		</div>
		<Divider />
		<List>{adminListItems}</List>
	</Drawer>
);

const NavigationUser = ({ classes, open, handleDrawerClose }) => (
	<Drawer
		variant="permanent"
		classes={{
			paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
		}}
		open={open}
	>
		<div className={classes.toolbarIcon}>
			<IconButton onClick={handleDrawerClose}>
				<ChevronLeftIcon />
			</IconButton>
		</div>
		<Divider />
		<List>{userListItems}</List>
	</Drawer>
);

export const Navigation = ({ open, handleDrawerClose, admin }) => {
	const classes = useStyles();

	return admin ? (
		<NavigationAdmin classes={classes} open={open} handleDrawerClose={handleDrawerClose} />
	) : (
		<NavigationUser classes={classes} open={open} handleDrawerClose={handleDrawerClose} />
	);
};
