import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		background: '#FAFAFA'
	},
	loader: {
		left: '50%',
		after: {
			left: '50%',
			marginLeft: '-4em'
		}
	}
}));

const Spinner = () => {
	const classes = useStyles();
	return (
		<Grid
			container
			spacing={0}
			direction="column"
			alignItems="center"
			justify="center"
			style={{ minHeight: '100vh' }}
		>
			<Grid item xs={3}>
				<CircularProgress className={classes.loader} />
			</Grid>
		</Grid>
	);
};

export default Spinner;
