import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Calendar from '@toast-ui/react-calendar';
import 'tui-calendar/dist/tui-calendar.css';
import app from '../Firebase';
import Spinner from '../Spinner';
import Fab from '@material-ui/core/Fab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforetIcon from '@material-ui/icons/NavigateBefore';
import CalendarTodaytIcon from '@material-ui/icons/CalendarToday';
import DialogInfo from '../DialogInfo';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { useSession } from '../Session';

const useStyles = makeStyles((theme) => ({
	eventConainer: {
		width: '100%',
		minWidth: 800,
		height: '100vh',
		padding: theme.spacing(4, 0)
	},
	classHeightCalendar: {
		height: 800
	},
	navCalendar: {
		margin: theme.spacing(2, 0, 4, 0)
	},
	navElementCalendar: {
		marginRight: theme.spacing(2),
		backgroundColor: '#BECD00',
		'&:hover, &:focus': {
			backgroundColor: '#BECD00'
		}
	},
	navElementCalendarToday: {
		backgroundColor: '#BECD00',
		'&:hover, &:focus': {
			backgroundColor: '#BECD00'
		},
		margin: theme.spacing(0, 2, 0, 4)
	},
	extendedIcon: {
		marginRight: theme.spacing(1)
	},
	monthView: {
		backgroundColor: '#515CE6',
		'&:hover, &:focus': {
			backgroundColor: '#515CE6'
		},
		color: 'white'
	}
}));

function useEvents() {
	const [ events, setEvents ] = useState([]);
	const [ calendars, setCalendars ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState('');
	const user = useSession();
	
	useEffect(() => {
		setLoading(true);
		const unsubscribe = () => {
			const ref = app.firestore().collection('calendars').where('users', 'array-contains', user.uid);
			const eventsCollection = ref.get().then((querySnapshotRef) => {
				querySnapshotRef.forEach(function(document) {
					const calendars = [];
					calendars.push(document.data());
					document.ref.collection('schedule').onSnapshot((querySnapshot) => {
						const newEvents = querySnapshot.docs.map((doc) => ({
							id: doc.id,
							title: doc.data().title,
							start: doc.data().start.toDate(),
							end: doc.data().end.toDate(),
							category: doc.data().category,
							adress: doc.data().adress,
							diffusion: doc.data().diffusion,
							district: doc.data().district,
							emailOfContact: doc.data().emailOfContact,
							entity: doc.data().entity,
							featuresGroup: doc.data().featuresGroup,
							inscriptions: doc.data().inscriptions,
							kindOfGroup: doc.data().kindOfGroup,
							level: doc.data().level,
							nameOfContact: doc.data().nameOfContact,
							participants: doc.data().participants,
							remark: doc.data().remark,
							phoneOfContact: doc.data().phoneOfContact
						}));

						setLoading(false);
						setEvents(newEvents);
					});
					setCalendars(calendars);
				});
			});
		};
		unsubscribe();
	}, []);

	return { events, calendars, loading, error };
}

const EventCalendarContainer = () => {
	const classes = useStyles();
	const myTheme = {
		'common.border': '1px solid #e5e5e5',
		'common.backgroundColor': 'white',
		'common.holiday.color': '#ff4040',
		'common.saturday.color': '#333',
		'common.dayname.color': '#333',
		'common.today.color': '#333',
		// month header 'dayname'
		'month.dayname.height': '39px',
		'month.dayname.borderLeft': '1px solid #e5e5e5',
		'month.dayname.paddingLeft': '10px',
		'month.dayname.paddingRight': '10px',
		'month.dayname.backgroundColor': 'inherit',
		'month.dayname.fontSize': '15px',
		'month.dayname.fontWeight': 'normal',
		'month.dayname.textAlign': 'left',

		// month day grid cell 'day'
		'month.holidayExceptThisMonth.color': 'rgba(255, 64, 64, 0.4)',
		'month.dayExceptThisMonth.color': 'rgba(51, 51, 51, 0.4)',
		'month.weekend.backgroundColor': '#fafafa',
		'month.day.fontSize': '14px'
	};
	const initialMonth = new Date();
	moment.updateLocale('pl', {
		months: [
			'ENERO',
			'FEBRERO',
			'MARZO',
			'ABRIL',
			'MAYO',
			'JUNIO',
			'JULIO',
			'AGOSTO',
			'SEPTIEMBRE',
			'OCTUBRE',
			'NOVIEMBRE',
			'DICIEMBRE'
		]
	});
	const [ open, setOpen ] = React.useState(false);
	const [ info, setInfo ] = React.useState([]);
	const [ month, setMonth ] = React.useState(moment(initialMonth).format('MMMM YYYY'));
	const [ day, setDay ] = React.useState(moment(initialMonth).format('DD MMMM'));
	const { events, calendars, loading, error } = useEvents();
	const refContainer = useRef(null);

	const handleClickNextButton = () => {
		const calendarInstance = refContainer.current.getInstance();
		setMonth(moment(refContainer.current.getInstance().getDateRangeEnd().toDate()).format('MMMM YYYY'));
		calendarInstance.next();
	};

	const handleClickNextBefore = () => {
		const calendarInstance = refContainer.current.getInstance();
		setMonth(
			moment(refContainer.current.getInstance().getDate().toDate()).subtract(1, 'months').format('MMMM YYYY')
		);
		calendarInstance.prev();
	};

	const handleClickToday = () => {
		const calendarInstance = refContainer.current.getInstance();
		setMonth(moment(calendarInstance.today()).format('MMMM YYYY'));
		calendarInstance.today();
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickOpenCalendar = (ev) => {
		const event = events.find((event) => event.id === ev.schedule.id);
		setInfo(event);
		setOpen(true);
	};

	if (loading) {
		return (
			<Spinner />
		);
	}

	return (
		<Paper elevation={3} className={classes.eventConainer}>
			<div className={classes.navCalendar}>
				<Fab
					variant="extended"
					color="primary"
					aria-label="add"
					className={classes.navElementCalendarToday}
					onClick={handleClickToday}
				>
					<CalendarTodaytIcon className={classes.extendedIcon} />
					Hoy - {day}
				</Fab>
				<Fab
					color="primary"
					size="medium"
					aria-label="before"
					onClick={handleClickNextBefore}
					className={classes.navElementCalendar}
				>
					<NavigateBeforetIcon />
				</Fab>
				<Fab
					color="primary"
					size="medium"
					aria-label="next"
					onClick={handleClickNextButton}
					className={classes.navElementCalendar}
				>
					<NavigateNextIcon />
				</Fab>
				<Fab variant="extended" color="primary" aria-label="add" className={classes.monthView}>
					{month}
				</Fab>
			</div>
			<Calendar
				ref={refContainer}
				usageStatistics={false}
				calendars={calendars}
				view={'month'}
				height={800}
				disableDblClick={true}
				disableClick={true}
				isReadOnly={true}
				month={{
					daynames: [ 'Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab' ],
					startDayOfWeek: 1
				}}
				schedules={events}
				scheduleView={false}
				taskView={false}
				theme={myTheme}
				timezones={[
					{
						timezoneOffset: -60,
						displayLabel: 'GMT+01:00',
						tooltip: 'Madrid'
					}
				]}
				immediately={true}
				useDetailPopup={false}
				useCreationPopup={false}
				onClickSchedule={handleClickOpenCalendar}
				week={{
					startDayOfWeek: 1,
					daynames: [ 'Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab' ],
					showTimezoneCollapseButton: true,
					timezonesCollapsed: true
				}}
			/>
			<DialogInfo open={open} handleClose={handleClose} event={info} />
		</Paper>
	);
};

export default EventCalendarContainer;
